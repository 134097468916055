<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">客户列表</p>
            <div>
                <p class="btn-wrap-width" @click="addMyBrandPartnersToMeeting" style="margin-left: 10px;">
                    将已有品牌客户添加到此会议
                </p>
                <p class="btn-wrap-width" @click="doShowAddPartnerDialog" style="margin-left: 10px;">新增品牌客户</p>
            </div>
        </div>
        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">客户名称</div>
                <div class="table-name">客户邮箱</div>
                <div class="table-type">客户logo</div>
                <div class="table-operation">操作</div>
            </div>
            <div v-for="(partner, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{ (index + 1) }}</div>
                <div class="table-name" style="font-weight: bold;">{{ partner.partner.name }}</div>
                <div class="table-name" style="font-weight: bold;">{{ partner.partner.master_email }}</div>
                <div class="table-type" style="font-weight: bold;">
                    <img :src="partner.partner.logo_url" style="width: 64px;height: 64px;object-fit: cover;">
                </div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;"
                        @click="editPartner(partner.partner)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemovePartner(partner._id.$id)">删除</p>
                </div>
            </div>
        </div>
<!-- 
        <el-dialog :visible.sync="showAddPartnerDialog" :title="partyInfo.id == '' ? '添加客户' : '编辑客户'" width="55%">
            <div>
                <div>
                    <el-form :model="partyInfo" :rules="rules" ref="partyInfo">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="partyInfo.name" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="单位名称" prop="company">
                            <el-input v-model="partyInfo.company" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="登录账号" prop="master_email">
                            <el-input v-model="partyInfo.master_email" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="Logo">
                            <el-upload class="avatar-uploader" action="/" :show-file-list="false"
                                :before-upload="(file) => beforeLogoUpload(file)">
                                <img v-if="partyInfo.logo_url" :src="partyInfo.logo_url" style="object-fit: cover"
                                    class="el-icon-plus avatar-uploader-icon" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="">
                            <label>介绍</label>
                            <quill-editor class="ql-container" style="margin-bottom: 20px" ref="myTextEditor"
                                v-model="partyInfo.intro" :options="editorOption">
                            </quill-editor>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="text-align: right;margin-top: 100px;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showAddPartnerDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confirmAddPartner()">确 定</el-button>
                    </span>
                </div>
            </div>
        </el-dialog> -->
        <el-dialog :visible.sync="showBrandPartnersDialog" title="添加品牌客户" width="55%">
        <div>
            <!-- Content for the brand partners dialog can be added here -->
                <el-select v-model="selectedPartners" multiple placeholder="请选择品牌客户">
                    <el-option
                        v-for="partner in availablePartners"
                        :key="partner._id.$id"
                        :label="partner.name"
                        :value="partner._id.$id">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showBrandPartnersDialog = false">关闭</el-button>
                <el-button type="primary" @click="confirmAddBrandPartnersToMeeting">添加</el-button>
            </span>
        </el-dialog>

        <AddPartner
            ref="addPartner"
            :meetingId="meeting_id"
            @on-complete="fetchData"
        ></AddPartner>
    </div>
</template>
<script>
import {
    getQiniuToken,
    updatePartner,
    addMettingPartner,
    removeMeetingPartner,
    getMeetingPartners,
    postRequest
} from '../../../api/api'
import {
    getUserId
} from '../../../utils/store'
import * as qiniu from "qiniu-js";
import '../../../assets/common/common.css'
import AddPartner from './addPartner.vue';
export default ({
    name: 'index',
    components: {
        AddPartner
    },
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            showAddPartnerDialog: false,
            partyInfo: {
                id: "",
                name: "",
                logo_url: '',
                company: '',
                intro: '',
                master_email: '',
                organizer_id: getUserId()
            },
            rules: {
                master_email: [{ required: true, message: "Please input", trigger: "blur" }],
                name: [{ required: true, message: "Please input", trigger: "blur" }],
                company: [{ required: true, message: "Please input", trigger: "blur" }]
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                        ["blockquote", "code-block"], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                        [{ script: "sub" }, { script: "super" }], // 上标/下标
                        [{ indent: "-1" }, { indent: "+1" }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ["small", false, "large", "huge"] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ["clean"], // 清除文本格式
                        ["link", "image", "video"], // 链接、图片、视频
                    ], // 工具菜单栏配置
                },
                placeholder: "", // 提示
                readyOnly: false, // 是否只读
                theme: "snow", // 主题 snow/bubble
                syntax: true, // 语法检测
            },
            showBrandPartnersDialog: false,
            availablePartners: [],
            selectedPartners: [],
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        editPartner(row) {
            this.$refs.addPartner.show(row)
        },

        doShowAddPartnerDialog() {
            console.log(this.$refs)
            this.$refs.addPartner.show()
        },

        confirmAddPartner() {
            this.$refs.partyInfo.validate((val) => {
                if (val) {
                    updatePartner(this.partyInfo).then((res => {
                        let partner_id = res.data.data._id.$id
                        if (this.partyInfo.id == '') {
                            this.doAddMettingPartner(partner_id)
                        } else {
                            this.showAddPartnerDialog = false
                        }
                    }))
                }
            })
        },
        doAddMettingPartner(partner_id) {
            addMettingPartner(this.meeting_id, partner_id, getUserId()).then((res) => {
                let result = res.data
                if (result.code != 0) {
                    this.$message.warning(result.msg)
                    return
                }
                this.$message.success('添加成功')
                this.fetchData()
                this.showAddPartnerDialog = false
            })
        },

        async beforeLogoUpload(file) {
            const result = await getQiniuToken();
            if (result.data.code === 0) {
                const token = result.data.data;
                this.observable = qiniu.upload(
                    file,
                    new Date().getTime() + file.name,
                    token
                );
                const observer = {
                    complete: (res) => {
                        this.partyInfo.logo_url =
                            "http://mainbucket.reachable-edu.com/" + res.key;
                    },
                };
                this.observable.subscribe(observer);
            }
        },

        fetchData() {
            getMeetingPartners(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemovePartner(id) {
            this.$alert('你确定要删吃此品牌客户吗？', '提示', {
                callback: action => {
                    if (action == 'confirm') {
                        removeMeetingPartner(id).then((res) => {
                            this.$message.success('删除成功')
                            this.fetchData()
                        })
                    }
                }
            })
        },

        addMyBrandPartnersToMeeting() {
            this.showBrandPartnersDialog = true;
            this.getAvailablePartners();
        },
        getAvailablePartners() {
            postRequest({
                function: 'getAvailablePartners',
                meeting_id: this.meeting_id
            }).then((res) => {
                this.availablePartners = res.data.data
            })
        },

        confirmAddBrandPartnersToMeeting() {
            if (!this.selectedPartners.length) {
                this.$message.warning('请选择要添加的品牌客户');
                return;
            }
            
            postRequest({
                function: 'addBrandPartnersToMeeting',
                meeting_id: this.meeting_id,
                partner_ids: this.selectedPartners
            }).then(res => {
                if (res.data.code === 0) {
                    this.$message.success('添加成功');
                    this.showBrandPartnersDialog = false;
                    this.fetchData();
                } else {
                    this.$message.error(res.data.msg || '添加失败');
                }
                })
                .catch(err => {
                    console.error('添加品牌客户失败:', err);
                    this.$message.error('添加失败，请稍后重试');
                });
            },
        }

    
})
</script>
<style>
.ql-container {
    height: 160px !important;
}
</style>
<style scoped lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
}

.avatar {
    width: 72px;
    height: 72px;
    display: block;
}

.table-name {
    flex: 3;
}

.table-type {
    flex: 3;
}

.table-operation {
    flex: 1.5;
}
</style>